/* eslint-disable */

import { push, INIT, PAGE_VIEW } from '@/lib/gtm'
import { isEmpty, once } from 'lodash'

export default ({ app }) => {

  // only if GTM has been setup
  if (isEmpty(process.env.GTM_ID)) {
    return
  }

  // Code from Google Tag Manager for the setup
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer',process.env.GTM_ID);


  const initGTM = once((data) => {
    push(INIT, data)
  })

  app.router.afterEach((to, from) => {
    let data = {
      virtualPageUrl: to.fullPath,
      virtualReferralUrl: from.fullPath,
      routeName: to.name,
    }

    initGTM(data)

    push(PAGE_VIEW, data)
  })
}
