<template lang="pug">
.h-notification-list
  //- .d-flex.align-center.ml-3.my-1
  //-   v-checkbox(
  //-     hide-details
  //-     label="Notification"
  //-   ).mt-0.pt-0
  //- v-divider
  v-slide-y-reverse-transition(group)
    template(v-for="(notification, key) in notifications")
      h-notification-list-item(v-if="notification" v-bind="$attrs" :notification="notification" :key="key").px-3.py-1
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    notifications: {
      type: [ Object, Array ],
      required: true,
    }
  },
}
</script>

<style lang="scss">
.h-notification-list {
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 6px;
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-image: -webkit-gradient(linear,
      left bottom,
      left top,
      color-stop(0.44, var(--v-grey-500-base)),
      color-stop(0.72, var(--v-grey-600-base)),
      color-stop(0.86, var(--v-grey-700-base)));
  }
}
</style>
