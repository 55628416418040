const middleware = {}

middleware['authenticated/redirect-authenticated'] = require('../app/middleware/authenticated/redirect-authenticated.js')
middleware['authenticated/redirect-authenticated'] = middleware['authenticated/redirect-authenticated'].default || middleware['authenticated/redirect-authenticated']

middleware['check-for-update'] = require('../app/middleware/check-for-update.js')
middleware['check-for-update'] = middleware['check-for-update'].default || middleware['check-for-update']

middleware['dev-only'] = require('../app/middleware/dev-only.js')
middleware['dev-only'] = middleware['dev-only'].default || middleware['dev-only']

export default middleware
