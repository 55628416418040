<template lang="pug">
  .h-card-text(:class="classes")
    slot
</template>

<script>
import Colorable from 'vuetify/lib/mixins/colorable'
import { Sizeable } from '@/mixins'

export default {
  mixins: [ Colorable, Sizeable ],
  computed: {
    classes () {
      return {
        ...this.sizeClasses
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .h-card-text {
    padding: 20px;
    @include md-and-up {
      padding: 30px;
    }

    &--small {
      padding: 12px;
      @include md-and-up {
        padding: 15px;
      }
    }

    &--semi-medium {
      padding: 20px;
      @include md-and-up {
        padding: 25px;
      }
    }

    &--large {
      padding: 40px 45px;
      @include md-and-up {
        padding: 54px 60px;
      }
    }
  }
</style>
