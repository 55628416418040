export default {
  methods: {
    authenticatedUserHasFeature (feature) {
      // unauthenticated users don't have access to conditional features
      if (!this.userIsAuthenticated) {
        return false
      }
      return this.authenticatedUser.featureFlags[feature]
    }

  }
}
