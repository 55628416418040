import faker from 'faker/locale/en_AU'
import shuffle from 'lodash/shuffle'

const subject = (notifiable) => {
  return shuffle([
    'Welcome to the family ' + notifiable.name + ' :)',
    'Oops you forgot your password! So did we..',
    'Please verify your email address',
    'Your verification code is ' + faker.random.alphaNumeric(6),
    'Thanks for your purchase of the ' + faker.commerce.product(),
    'Hey, interested in $' + faker.commerce.price() + '?'
  ])[0]
}

export default (notifiable) => {
  const to = notifiable.name

  let subTitleImage
  if (to.indexOf('gmail') > 0) {
    subTitleImage = 'https://mail.google.com/favicon.ico'
  } else if (to.indexOf('yahoo') > 0) {
    subTitleImage = 'https://yahoo.com/favicon.ico'
  } else if (to.indexOf('hotmail') > 0 || to.indexOf('outlook') > 0) {
    subTitleImage = 'https://outlook.live.com/owa/favicon.ico'
  }

  return {
    type: 'mail',
    vendor: 'smtp',
    errors: 0,
    warnings: 0,
    textHTML: faker.lorem.sentences(5),
    from: {
      name: faker.name.findName(),
      email: faker.internet.email(),
    },
    title: subject(notifiable),
    subTitle: to,
    subTitleImage,
    to: notifiable.email
  }
}
