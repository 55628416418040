<template lang="pug">
v-card(
  v-bind="$attrs"
  v-on="$listeners"
  :color="color"
  :raised="false"
  :class="classes"
  :style="style"
).h-card.pa-0
  slot
  a(v-if="closable" @click="$emit('close')").h-card__button--close
    slot(name="close")
      h-icon(color="brand-dark-blue") close
</template>

<script>
export default {
  props: {
    color: {
      default: 'white',
      type: String
    },
    closable: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    border: {
      type: String,
      default: '',
    }
  },
  computed: {
    classes () {
      return {
        'box-shadow--small': !this.flat,
        'box-shadow--none': this.flat
      }
    },
    style () {
      return {
        border: this.border ? '1px solid var(--v-' + this.border + '-base) !important' : false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.h-card {
  overflow: hidden;
  border-radius: 0px;
  position: relative;

  &__button--close {
    color: #fff;
    height: 20px;
    opacity: .3;
    position: absolute;
    right: 1em;
    top: 1em;
    width: 20px;
  }
}
</style>
