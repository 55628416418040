<template lang="pug">
v-app
  h-header(app)
  v-main
    h-loader(:value="isLoading" large)
      template(v-if="activeInbox")
        //- .primary-800.grey-400--text.d-flex.justify-content-start.px-5
        //-   .d-inline-block.filter
        //-     .px-3.py-2
        //-       .paragraph-x-small.grey-700--text Environment
        //-       .d-flex.justify-space-between.align-center
        //-         .paragraph-small All
        //-         v-icon(color="white" size="10") $vuetify.icons.down
        //-   .d-inline-block.mr-3.filter
        //-     .px-3.py-2
        //-       .paragraph-x-small.grey-700--text Notifications
        //-       .d-flex.justify-space-between.align-center
        //-         .paragraph-small All
        //-         v-icon(color="white" size="10") $vuetify.icons.down
        //-   .d-flex.align-center
        //-     v-text-field(
        //-       placeholder="search your notifications"
        //-       small
        //-       hide-details
        //-       solo
        //-       prepend-inner-icon="$search"
        //-     ).filter.search
        .d-flex.mt-8.mx-8
          .sidebar(:class="{ 'sidebar--big': $route.name === 'team-inboxes-inbox-notification' }")
            .sidebar__inner
              template(v-if="$route.name === 'team-inboxes-inbox-inbox'")
                .mb-6.mr-10
                  .paragraph-x-small.grey-600--text.mb-2.font-weight-semibold Actions
                  a.d-block.grey-700--text.paragraph-small.mb-2.d-flex.align-center
                    v-icon(color="grey-600" size="10").d-inline-block.mr-1 $vuetify.icons.mail
                    span Refresh
                  a.d-block.grey-700--text.paragraph-small.mb-2.d-flex.align-center
                    v-icon(color="grey-600" size="10").d-inline-block.mr-1 $vuetify.icons.check
                    span Mark all read
                  a.d-block.grey-700--text.paragraph-small.mb-2.d-flex.align-center
                    v-icon(color="grey-600" size="10").d-inline-block.mr-1 $vuetify.icons.times
                    span Empty Inbox

                .mb-10.mr-10
                  .paragraph-x-small.grey-600--text.mb-2.font-weight-semibold Environment
                  a(@click="environment = 'UAT'" :class="{ 'filter--active': environment === 'UAT' }").filter.d-block.paragraph-small.mb-1.d-flex.justify-space-between.align-center
                    span UAT
                    span.small 55
                  a(@click="environment = 'Staging'" :class="{ 'filter--active': environment === 'Staging' }").filter.d-block.paragraph-small.mb-1.d-flex.justify-space-between.align-center
                    span Staging
                    span.small 30

                .mb-10.mr-10
                  .paragraph-x-small.grey-600--text.mb-2.font-weight-semibold Notifications
                  a(@click="notificationTypes = 'All'" :class="{ 'filter--active': notificationTypes === 'All' }").filter.d-block.paragraph-small.mb-1.d-flex.justify-space-between.align-center
                    span All
                    span.small 55
                  a(@click="notificationTypes = 'Mail'" :class="{ 'filter--active': notificationTypes === 'Mail' }").filter.d-block.paragraph-small.mb-1.d-flex.justify-space-between.align-center
                    span Mail
                    span.small 55
                  a(@click="notificationTypes = 'SMS'" :class="{ 'filter--active': notificationTypes === 'SMS' }").filter.d-block.paragraph-small.mb-1.d-flex.justify-space-between.align-center
                    span SMS
                    span.small 55
                  a(@click="notificationTypes = 'Slack'" :class="{ 'filter--active': notificationTypes === 'Slack' }").filter.d-block.paragraph-small.mb-1.d-flex.justify-space-between.align-center
                    span Slack
                    span.small 55
                .mb-10.mr-10
                  .paragraph-x-small.grey-600--text.mb-2.font-weight-semibold Severity
                  a(@click="severity = 'All'" :class="{ 'filter--active': severity === 'All' }").filter.d-block.paragraph-small.mb-1.d-flex.justify-space-between.align-center
                    span All
                    span.small 55
                  a(@click="severity = 'Errors'" :class="{ 'filter--active': severity === 'Errors' }").filter.d-block.paragraph-small.mb-1.d-flex.justify-space-between.align-center
                    span Errors
                    span.small 30
              template(v-if="$route.name === 'team-inboxes-inbox-notification'")
                .d-flex.align-center.justify-space-between.mb-2
                  nuxt-link(:to="'/' + $route.params.team + '/inboxes/' + activeInbox.name + '/inbox'").grey-600--text.paragraph-x-small.d-block Back to {{ activeInbox.name }}
                  h-button(x-small  color="yellow-300").ml-5
                    .yellow-800--text Delete All
                h-notification-list(small :notifications="notifications").white
          .content
            nuxt
</template>


<script>
import Pusher from 'pusher-js'
import { sync } from 'vuex-pathify'

export default {
  middleware: 'auth',
  data () {
    return {
      pusher: null,
      channel: null,
      isLoading: false,
      isDeletingInbox: false,
      isSavingInbox: false,

      environment: 'UAT',
      notificationTypes: 'All',
      severity: 'All',
    }
  },
  computed: {
    ...sync({
      activeInbox: 'authenticated/activeInbox',
      activeNotification: 'authenticated/activeNotification',
      notifications: 'authenticated/notifications',
    }),
    inboxUrl () {
      return '/' + this.$auth.user.default_team + '/inboxes/' + this.activeInbox.name
    },
    resourceUrl () {
      return 'https://api.unset.email/teams/' + this.$route.params.team + '/inboxes/' + this.$route.params.inbox
    }
  },
  async mounted () {
    console.log(this.$route.name)
    this.isLoading = true
    this.activeInbox = await this.$axios.$get(this.resourceUrl)
    this.notifications = require('~~/resources/mock/notifications.js').default
    console.log(this.notifications)
    this.isLoading = false

    if (this.notifications.length <= 0) {
      this.$router.push(this.inboxUrl + '/integration')
    }

    const pusher = new Pusher('788563f8de2d366181a3', {
      cluster: 'ap4'
    })

    const channel = pusher.subscribe('inbox-' + this.$route.params.team + '_' + this.$route.params.inbox)
    channel.bind('notification-created', (data) => {
      this.notifications.unshift(data)
    })
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .h-notification-list {
    height: calc(100vh - 51px - 46px);
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;
      border-radius: 6px;
    }

    &::-webkit-scrollbar {
      width: 8px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-image: -webkit-gradient(linear,
        left bottom,
        left top,
        color-stop(0.44, var(--v-grey-500-base)),
        color-stop(0.72, var(--v-grey-600-base)),
        color-stop(0.86, var(--v-grey-700-base)));
    }
  }
  .content {
    flex-grow: 1;
    max-width: calc(100% - 11rem);
    overflow-x: hidden;
    min-height: calc(100vh - 8rem);
  }
  .filter {
    cursor: pointer;
    transition: 0.3s background-color;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
    padding-right: 20px;
    margin-left: -500px;
    padding-left: 500px;
    padding-top: 3px;
    padding-bottom: 3px;
    color: var(--v-grey-700-base) !important;
    &:hover {
      background-color: var(--v-grey-400-base);
    }
    &--active {
      background-color: var(--v-yellow-100-base);
      font-weight: 600;
      color: var(--v-yellow-700-base) !important;
      &:hover {
        background-color: var(--v-yellow-200-base);
      }
    }

  }
  .search {
    vertical-align: middle;
    width: 300px;
    .v-input__slot {
      padding: 0 3px !important;
      border: 1px solid var(--v-primary-800-base) !important;
      background-color: var(--v-primary-900-base) !important;
      color: white !important;
      input::placeholder {
        color: var(--v-grey-700-base) !important;
      }
    }
    .v-input__control {
      min-height: 32px !important;
    }

    input {
      color: white !important;
    }

    svg {
      height: 14px !important;
      width: 14px !important;
      color: var(--v-grey-800-base) !important;
    }
  }
  .sidebar {
    position: relative;
    width: 11rem;
    flex-shrink: 0;
    max-height: calc(100vh - 8rem);

    &--big {
      width: 20rem;

      .sidebar__inner {
        width: 20rem;
      }
    }

    &__inner {
      position: sticky;
      top: 100px;
      background: #f8f8f8;
      height: calc(100vh - 8rem);
      width: 11rem;
      will-change: top,left;
      display: flex;
      flex-direction: column;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;
      border-radius: 6px;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-image: -webkit-gradient(linear,
        left bottom,
        left top,
        color-stop(0.44, var(--v-grey-500-base)),
        color-stop(0.72, var(--v-grey-600-base)),
        color-stop(0.86, var(--v-grey-700-base)));
    }
  }
  .theme--light.v-tabs > .v-tabs-bar {
    background-color: transparent !important;
  }
  .v-tab {
    letter-spacing: normal !important;
    text-transform: capitalize !important;
    font-family: $heading-font-family;
    justify-content: start;
    padding: 5px 5px 5px 10px;
    height: auto;

    &--active {
      color: var(--v-primary-700-base) !important;
    }

    @extend %paragraph-small;
  }
}
</style>
