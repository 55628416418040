import Vue from 'vue'
import VueCodeHighlight from 'vue-code-highlight'
import 'prism-es6/components/prism-markup-templating'
import 'prism-es6/components/prism-php'
import 'prism-es6/components/prism-bash'

// you have to pass your own global paper instance to PaperVueify.

export default (ctx, inject) => {
  Vue.use(VueCodeHighlight) // registers the v-highlight directive
}
