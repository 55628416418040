import faker from 'faker/locale/en_AU'
import random from 'lodash/random'

const notifiables = []

for (let n = 0; n < 6; n++) {
  notifiables.push(require('./notifiable').default())
}

const notifications = []

for (let i = 0; i < random(75, 125); i++) {
  const notifiable = notifiables[random(notifiables.length - 1)]

  const number = random(10)

  let notificationTemplate
  if (number <= 6) {
    notificationTemplate = require('./mail').default
  } else if (number <= 8) {
    notificationTemplate = require('./sms').default
  } else {
    notificationTemplate = require('./slack').default
  }
  const notificationId = faker.random.uuid()

  const notification = {
    ...notificationTemplate(notifiable),
    timestamp: faker.date.recent(),
    notifiable,
    notificationId,
    s3Key: 'loonpwn/kintell/' + notificationId,
    status: 'processed',
    inboxId: 'test',
    teamId: 'harlan',
    'teamId+inboxId': 'loonpwn_kintell',
  }

  if (faker.random.number(10) >= 9) {
    notification.errors = [ 'broken link' ]
  }

  if (faker.random.number(10) >= 5) {
    notification.readAt = faker.date.recent()
  }

  notifications.push(notification)
}

export default notifications.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
