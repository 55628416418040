import { get } from 'vuex-pathify'
import UsesFeatureFlags from './UsesFeatureFlags'

export default {
  mixins: [ UsesFeatureFlags ],
  computed: {
    ...get({
      userIsAuthenticated: 'auth/loggedIn',
      authenticatedUser: 'auth/user'
    }),
  }
}
