<template lang="pug">
.h-header(:class="classes").heading-font-family
  //- Nav bar with links
  .px-8.h-header__links.hidden-md-and-down
    .d-flex.align-center
      .text-left.d-inline-flex.align-center
        template(v-if="!$auth.loggedIn")
          nuxt-link(to="/").d-inline-flex.pa-2.h-header__home-link.unstyled--link
            .d-flex.align-center
              .heading-font-family.font-weight-bold.paragraph-large unset
              img(:src="require('~icons/logo.svg')" height="10" width="10").d-inline-block.mt-1.ml-1
        div(v-else ref="menu" @click.prevent="toggleOpen").d-flex.align-center.h-header__nav
          .h-header__nav-icon.d-inline-block.cursor-pointer.mr-1
            .h-header__nav-icon-bar(ref="topBar")
            .h-header__nav-icon-bar(ref="middleBar")
            .h-header__nav-icon-bar(ref="bottomBar")
        template(v-if="activeInbox")
          nuxt-link(:to="'/' + $auth.user.default_team + '/inboxes/' + activeInbox.name").d-flex.align-center.h-header__link.mr-3.ml-7
            .pa-2.d-flex.align-center
              .paragraph-regular {{ activeInbox.name }}

          nuxt-link(:to="'/' + $auth.user.default_team + '/inboxes/' + activeInbox.name + '/inbox'").d-flex.align-center.h-header__link.mr-3
            .pa-2.d-flex.align-center
              v-icon(color="inherit" size="14").d-inline-block.mr-2 $vuetify.icons.inbox
              .paragraph-regular Inbox
          v-text-field(
            v-if="$route.name === 'team-inboxes-inbox-inbox'"
            placeholder="search notifications"
            small
            hide-details
            solo
            prepend-inner-icon="$search"
            append-icon="$expand"
          ).search.mr-5
          nuxt-link(:to="'/' + $auth.user.default_team + '/inboxes/' + activeInbox.name + '/snapshots'").d-flex.align-center.h-header__link
            .pa-2.d-flex.align-center
              v-icon(color="inherit" size="14").d-inline-block.mr-2 $vuetify.icons.folder
              .paragraph-regular Snapshots
      v-spacer
      template(v-if="!$auth.loggedIn")
        .d-flex.align-center
          nuxt-link(to="/how-unset-works").mr-2.px-3.py-5 How Unset works
          nuxt-link(to="/pricing").mr-2.px-3.py-5 Pricing
          nuxt-link(to="/features").mr-2.px-3.py-5 Features
          span.mx-3.opacity-3 |
          a(@click="$auth.loginWith('auth0')").mr-2.px-3.py-5 Sign-In
          a(@click="$auth.loginWith('auth0', { params: { 'unset_showsignup': 1 }})").px-3.py-5 Sign-Up Free
      template(v-else)
        v-menu(offset-y allow-overflow bottom min-width="220")
          template(v-slot:activator="{ on, attrs }")
            a(v-on="on" v-bind="attrs").align-center.justify-center.d-flex.py-2.pl-2.unstyled--link
              v-avatar(size="30").mr-1
                img(:src="$auth.user.picture")
              v-icon(color="white" size="10") $vuetify.icons.down
          h-card-text(small).white
            .mb-3
              .font-weight-bold.paragraph-x-small.mb-1 {{ $auth.user.name }}
              .paragraph-x-small.grey-700--text {{ $auth.user.email }}
            v-divider.mb-3
            nuxt-link(to="/profile/settings").d-block.mb-3
              v-icon(size="12" color="primary").mr-2 $vuetify.icons.settings
              .paragraph-small.d-inline-block.black--text Settings
            a(@click="$auth.logout()")
              v-icon(size="12" color="primary").mr-2 $vuetify.icons.logout
              .paragraph-small.d-inline-block.black--text Sign Out
  //- Mobile nav drawer
  v-navigation-drawer(
    fixed
    temporary
    v-model="opened"
  ).primary-800
    .mt-10.pt-10
    .mx-3
      nuxt-link(to="/" @click.native="toggleClose").d-inline-flex.pa-2.h-header__home-link.unstyled--link
        .d-flex.align-center
          .heading-font-family.font-weight-bold.paragraph-large.white--text unset
          img(:src="require('~icons/logo.svg')" height="10" width="10").d-inline-block.mt-1.ml-1
    .mt-5.mx-5
      .mb-8
        .white--text.font-weight-bold.mb-4 Inboxes
        nuxt-link(
          v-for="(inbox, key) in inboxes"
          :key="key"
          :to="'/' + $auth.user.default_team + '/inboxes/' + inbox.name"
          @click.native="toggleClose"
        ).white--text.unstyled--link.d-block.mb-3.paragraph-small {{ inbox.name }}
      .white--text.font-weight-bold.mb-4 Pages
      nuxt-link(
        v-for="(item, key) in drawerItems"
        :key="key"
        :to="item"
        @click.native="toggleClose"
      ).white--text.unstyled--link.d-block.paragraph-small.mb-3 {{ key }}
</template>
<script>
import { TimelineLite, Power4 } from 'gsap'
import { UsesAuthenticatedUser } from '@/mixins'
import { get } from 'vuex-pathify'

export default {
  mixins: [ UsesAuthenticatedUser ],
  props: {
    absolute: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      opened: false,
      tl: null,
      drawerItems: {
        'How Unset Works': '/how-unset-works',
        Pricing: '/pricing',
        Docs: '/documentation',
        Contact: '/contact',
      }
    }
  },
  computed: {
    ...get({
      activeInbox: 'authenticated/activeInbox',
      inboxes: 'authenticated/inboxes',
    }),
    classes () {
      return {
        'primary-900': !this.absolute,
        'h-header--not-absolute': !this.absolute,
        'h-header--absolute': this.absolute,
      }
    },
  },
  watch: {
    opened (val) {
      if (!val) {
        this.toggleClose()
      }
    }
  },
  methods: {
    toggleOpen () {
      if (this.opened) {
        this.toggleClose()
        return
      }
      this.opened = true

      if (this.tl) {
        this.tl.play()
        return
      }

      this.tl = new TimelineLite()
      const { menu, topBar, bottomBar } = this.$refs

      this.tl.to(topBar, 0.2, { ease: Power4.easeOut, top: '50%' }, '0')
      this.tl.to(bottomBar, 0.2, { ease: Power4.easeOut, top: '50%' }, '0')
      this.tl.to(menu, 0.2, { ease: Power4.easeIn, top: '50%', rotation: 135 }, '0.2')
      this.tl.to(bottomBar, 0.2, { ease: Power4.easeIn, rotation: 90 }, '0.2')
    },
    toggleClose () {
      this.tl.reverse()
      this.opened = false
    }
  },
}
</script>

<style lang="scss">
$bar-w: 20px;
$bar-h: 2px;
$bar-color: white;

.h-header {
  z-index: 10;
  width: 100%;

  $root: &;

  &--absolute {
    position: absolute;
    top: 0;
    left: 0;
    color: white;
  }

  a, .nuxt-link {
    transition: .25s;
    position: relative;
    color: var(--v-grey-500-base) !important;

    &:hover {
      color: var(--v-grey-100-base) !important;
    }

    &:after {
      content: ' ';
      height: 2px;
      position: absolute;
      background-color: transparent;
      bottom: -2px;
      left: -75px;
      transition: .3s;
      width: 100%;
      border-radius: 8px;
    }
  }

  .nuxt-link-exact-active {
    color: var(--v-grey-100-base) !important;
    position: relative;
    &:after {
      background-color: var(--v-orange-base);
      left: 0;
    }
  }

  &__link {
    color: var(--v-grey-700-base) !important;
    &.nuxt-link-exact-active {
      color: var(--v-grey-100-base) !important;
    }
  }

  &__home-link {
    &:after {
      display: none;
    }
  }

  &__nav {
    cursor: pointer;
  }
  &__nav-icon {
    position: relative;
    width: $bar-w;
    height: 15px;
    cursor: pointer;
    z-index: 99;
    &-bar {
      box-sizing: border-box;
      position: absolute;
      right: 0;
      width: $bar-w;
      height: 2px;
      background-color: white;
      transform-origin: center center;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 50%;
      }
      &:nth-child(3) {
        top: 100%;
      }
    }
  }
}

</style>
