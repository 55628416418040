<template lang="pug">
nuxt-link(:to="url" :class="classes").d-block.unstyled--link.d-flex.align-center
  div(v-if="!small").h-notification-list-item__sub-title
    .h-notification-list-item__title.paragraph-small
      span(v-if="!small") {{ notification.subTitle }}
    .small.d-flex.align-center.h-notification-list-item__sub-title.small {{ notification.to }}
  .d-flex.align-center.justify-space-between.h-notification-list-item__wrap
    .shrink.d-flex.align-center
      img(v-if="notification.errors.length > 0" :src="require('~icons/logo-red.svg')" width="8").mr-1
      div(:class="{ 'paragraph-small': !small, 'paragraph-x-small': small }").h-notification-list-item__title.d-flex
        span {{ notification.title }}
    div(v-if="notification.textHTML && !small").ml-1.grey-600--text.paragraph-small.grow - {{ notification.textHTML }}
  div(v-if="small").small.d-flex.align-center.h-notification-list-item__sub-title.small {{ notification.to }}
  template(v-if="!small")
    .h-notification-list-item__timestamp.small.shrink.text-right {{ $moment(notification.timestamp).fromNow() }}
</template>

<script>
import { Sizeable } from '@/mixins'
import { sync } from 'vuex-pathify'

export default {
  mixins: [
    Sizeable
  ],
  props: {
    notification: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...sync({
      activeNotification: 'authenticated/activeNotification',
    }),
    alertImage () {
      if (this.notification.errors.length > 0) {
        return require('~icons/logo-red.svg')
      }
      return null
    },
    classes () {
      return {
        ...this.sizeClasses,
        'h-notification-list-item': true,
        'h-notification-list-item--slack': this.notification.type === 'webhook',
        'h-notification-list-item--mail': this.notification.type === 'mail',
        'h-notification-list-item--sms': this.notification.type === 'sms',
        'h-notification-list-item--error': this.notification.errors,
        'h-notification-list-item--warning': this.notification.warnings,
        'h-notification-list-item--read': !!this.notification.readAt,
        'h-notification-list-item--active': this.activeNotification && this.notification.notificationId === this.activeNotification.notificationId,
      }
    },
    url () {
      return '/' + this.notification.teamId + '/inboxes/' + this.notification.inboxId + '/' + this.notification.notificationId
    }
  }
}
</script>

<style lang="scss" scoped>
.h-notification-list-item {
  transition: 0.4s background-color;
  border-top: 1px solid #e2e9ef;
  position: relative;
  $root: &;

  &__wrap {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 20px;
  }

  &__title {
    font-weight: 600;
    color: var(--v-primary-900-base);
  }

  &__timestamp {
    font-weight: 600;
    color: var(--v-primary-900-base);
    white-space: nowrap;
    flex-basis: 120px;
  }

  &__sub-title {
    width: 200px;
    flex-basis: 200px;
    color: var(--v-grey-800-base);
  }

  &__alert {
    width: 50px;
  }

  &--read {
    background-color: var(--v-grey-300-base);
    #{$root}__title {
      font-weight: 400;
    }
    #{$root}__timestamp {
      font-weight: 400;
      color: var(--v-grey-700-base);
    }
    #{$root}__sub-title {
      color: var(--v-grey-700-base);
    }
  }

  &--active {
    background-color: var(--v-primary-700-base);
    #{$root}__title {
      font-weight: 600;
      color: white;
    }
  }

  &--small {
    flex-direction: column;

    #{$root}__wrap {
      width: 100% !important;
      flex-basis: 100% !important;
    }
    #{$root}__sub-title {
      width: 100% !important;
      flex-basis: 100% !important;
    }
  }

  &:hover {
    border-bottom-color: transparent;
    background-color: var(--v-yellow-100-base);
  }

  &__sub-title {
    &:hover {

    }
  }
}
</style>

