<template lang="pug">
v-container
  v-layout(justify-center)
    v-flex(sm6)
      h-card
        h-card-text
          h2.headline {{ message }}
          p.paragraph.py-12 Uh oh, we can’t seem to find the page you’re looking for. Try going back to previous page or #[nuxt-link(to="/get-in-touch").link--underline contact us] for more information.
          h-button(to="/" large) Home
</template>


<script>
export default {
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isDev: process.env.NODE_ENV !== 'production'
    }
  },
  computed: {
    is404Error () {
      return this.statusCode === 404
    },
    statusCode () {
      return (this.error ? this.error.statusCode : 'Client Error')
    },
    message () {
      return this.error.message || '<%= messages.client_error %>'
    }
  },
  head () {
    return {
      title: this.message + ' - Unset'
    }
  }
}
</script>
