import { validationMixin } from 'vuelidate'
import { forEach, startsWith, lowerCase, replace, kebabCase, map, sortBy, pickBy, filter, isFunction, isEmpty, flattenDeep, find } from 'lodash'
import { push, FORM_ERRORS } from '@/lib/gtm'
import ChangesScroll from '../ChangesScroll'
/**
 * Validates Mixin is our drop-in for form validation and utility functions. It handles form submissions, error messages,
 * API errors, translating errors, etc.
 */
export default {
  mixins: [
    validationMixin,
    ChangesScroll
  ],
  data () {
    return {
      /**
       * i18nKey is the i18n namespace for where to find the field error messages. This would typically be the component
       * or page name. Example: signUp for the Sign Up pages.
       */
      $_formI18nKey: '',
      /**
       * Within the above namespace (blank by default) where should it look for the error messages. You shouldn't need
       * to modify this
       */
      $_i18nErrorsKey: 'errors',
      /**
       * When scrolling to an error, what vertical offset from the field should the screen go to.
       */
      scrollToErrorYOffset: 50,
      /**
       * API errors in a key=>value pair for field=>errors
       */
      $_apiErrors: {},
      /**
       * Are we currently submitting the form
       */
      isSubmittingForm: false
    }
  },
  methods: {
    /**
     * We provide an instance of Vue component. Our code will then iterate through the refs recursively
     * @returns {array} Array of nodes
     * @param node
     */
    $_getRecursiveNodesThroughRefs (node) {
      if (isEmpty(node.$refs)) {
        return [ node ]
      }

      const validNodes = filter(node.$refs, node => !isEmpty(node))

      return [ node, ...map(validNodes, node => this.$_getRecursiveNodesThroughRefs(node)) ]
    },
    /**
     * Iterates through all children $refs to find components which have allValid as false
     * @returns {array}
     */
    $_findInvalidNodeDeep () {
      // recursively get the refs of the vue component
      let deepNodes = this.$_getRecursiveNodesThroughRefs(this)
      deepNodes = flattenDeep(deepNodes)
      // filter the refs that contain the validation mixin
      const nodeWithValidationMixin = filter(deepNodes, node => {
        // reset fieldErrors
        if (typeof node.$data !== 'undefined' && typeof node.$data.$_apiErrors !== 'undefined') {
          node.$data.$_apiErrors = {}
        }
        return isFunction(node.allValid)
      })
      // get the first ref that has an error
      return find(nodeWithValidationMixin, node => !node.allValid())
    },
    /**
     * Touches the vuelidate objects and checks if ALL fields are valid. This will trigger error messages. Note this
     * is for Vuelidate fields only, won't work for API errors
     * @returns {boolean}
     */
    allValid () {
      // just in-case someone is using this wrong
      if (typeof this.$v === 'undefined') {
        return true
      }
      this.$v.$touch()
      return !this.$v.$invalid
    },
    resetFields () {
      if (typeof this.$v === 'undefined') {
        return true
      }
      this.$v.$reset()
    },
    /**
     * Checks if there is a error and scrolls to it
     * @returns {boolean} True if there are no errors, false if there are
     */
    scrollToFirstError () {
      const errorRef = this.firstFieldWithError

      // error handling
      if (errorRef) {
        this.scrollToTarget(errorRef, { offset: this.scrollToErrorYOffset })
        return false
      }
      return true
    },
    /**
     * A bit of a monolith function that encapsulates the use case of validating a form for frontend validation,
     * submitting an API request and capturing the API errors and treating them as the frontend errors.
     *
     * It will: validate frontend fields, send API request, capture any errors, emit a GTM event.
     *
     * @param request Closure which will execute the API request and any other form submission logic.
     * @param error Any additional functionality needed when an error occurs, such as a toast or event.
     * @param scrollToTop
     * @returns {Promise<boolean>} True the request was completed successfully
     */
    async attemptFormApiSubmission (
      request,
      error = e => { this.$logger.debug('Empty error function', e) },
      scrollToTop = true
    ) {
      // make sure the user isn't already submitting the form
      if (this.isSubmittingForm) {
        return false
      }
      // by default the error function will just show a toast
      if (typeof error === 'undefined') {
        error = () => {
          this.$toast.error('There was an error submitting your details.')
        }
      }
      // get the first ref that has an error
      const errorRef = this.$_findInvalidNodeDeep()
      // validate submission
      if (errorRef) {
        // handle error and prevent api submission
        errorRef.scrollToFirstError()
        error()
        push(FORM_ERRORS)
        return false
      }

      // now do the API request

      // we flag that we're now doing the request to avoid subsequent requests and for snippers
      this.isSubmittingForm = true
      try {
        await request()
        // submitted successfully, reset validation objects
        this.$_apiErrors = {}
        if (scrollToTop) this.scrollToTop()
        // reset form fields to not be dirty
        const nodes = filter(flattenDeep(this.$_getRecursiveNodesThroughRefs(this)), node => isFunction(node.allValid))
        nodes.forEach(node => {
          node.resetFields()
        })
        return true
      } catch (e) {
        if (e.response && e.response.data && e.response.data.errors) {
          // recursively get the refs of the vue component
          const deepNodes = flattenDeep(this.$_getRecursiveNodesThroughRefs(this))
          // filter out Vue nodes
          const vueNodes = filter(deepNodes, (node) => node._isVue)
          // iterate each errors key
          map(e.response.data.errors, (error, key) => {
            // for each key, recursively find a child component with the component.$data.fields.$key
            const nodeWithField = find(vueNodes, (node) => {
              // check if this node actually has fields data
              if (typeof node.$data.fields === 'undefined' || typeof node.$data.fields[key] === 'undefined') {
                return false
              }
              return node.$data.fields && node.$data.fields[key]
            })
            if (!nodeWithField) {
              return false
            }
            this.$logger.debug('API Field Error', nodeWithField, key, error)
            // set the fieldErrors of the element containing $data.fields[key]
            this.$set(nodeWithField.$data.$_apiErrors, key, error)
            nodeWithField.scrollToFirstError()
            return nodeWithField
          })
          this.$_apiErrors = e.response.data.errors
          this.$logger.error(e.response.data.errors)
        } else {
          this.$logger.error(e)
        }
        error(e)
        // oops - an error
        push(FORM_ERRORS)
      } finally {
        // loading clean-up
        this.isSubmittingForm = false
      }
      return false
    },
    /**
     * This is internal function for finding the i18n key for a component, field and validation type.
     * @param field
     * @param validation
     * @param fieldI18nKey
     * @param label
     * @returns {*}
     */
    getErrorMessageForFieldValidation (field, validation, fieldI18nKey, label) {
      const fieldLabel = label || lowerCase(replace(kebabCase(fieldI18nKey), '-', ' '))
      // if they aren't valid, we generate an error message from the i18n files
      const translateData = {
        field: fieldLabel,
        // merge the field level validation configurations
        ...field.$params[validation]
      }

      // namespaces error field key exists with validation name e.g. kintellCards.errors.title.minLength
      const namespacedFieldKey = [
        this.$data.$_formI18nKey,
        this.$data.$_i18nErrorsKey,
        fieldI18nKey,
        validation
      ].join('.')
      // namespaces error field key exists with validation name e.g. kintellCards.errors.minLength
      const namespacedKey = [ this.$data.$_formI18nKey, this.$data.$_i18nErrorsKey, validation ].join('.')
      const globalFieldKey = [ this.$data.$_i18nErrorsKey, fieldI18nKey, validation ].join('.')

      let key = [ this.$data.$_i18nErrorsKey, validation ].join('.')
      if (this.$te(namespacedFieldKey)) {
        key = namespacedFieldKey
      } else if (this.$te(namespacedKey)) {
        key = namespacedKey
      } else if (this.$te(globalFieldKey)) {
        key = globalFieldKey
      }
      this.$logger.debug('Validation i18n Key', key, fieldI18nKey, validation)
      // use default message e.g. errors.minLength
      return this.$t(key, translateData)
    }
  },
  computed: {
    /**
     * Given a field key, we generate the default error messages from the language files. This merges in API errors as
     * well.
     * @returns {Array} A list of errors for the field
     */
    defaultErrorMessages () {
      return (field, label) => {
        if (!this.$v.fields[field]) {
          this.$logger.error('Getting default error message for field which does not exist', field, this.$v.fields)
          return []
        }
        const errors = []
        // if the input hasn't been changed, don't validate
        if (!this.$v.fields[field].$dirty) return errors
        // iterate through all fields that we are validation for
        forEach(this.$v.fields[field], (isValid, key) => {
          if (startsWith(key, '$')) { // check the property isn't private
            return
          }
          if (isValid) { // only if it's invalid
            return
          }
          const error = this.getErrorMessageForFieldValidation(this.$v.fields[field], key, field, label)
          errors.push(error)
        })
        // add server errors
        if (this.$data.$_apiErrors[field]) {
          errors.push(...this.$data.$_apiErrors[field])
        }
        return errors
      }
    },
    /**
     * Filter through all field inputs and sort them by their vertical position on the page
     * @returns {*}
     */
    firstFieldWithError () {
      // get only fields which have validation, are invalid and have a ref setup
      const $invalidFields = pickBy(this.fields, (value, key) =>
        this.$refs[key] && this.defaultErrorMessages(key).length > 0
      )
      // get the actual html fields for each validation field if there is no $el, just get the ref with that key (case: advising in < 3 errors just go to the ref of the title)
      const $fields = map($invalidFields, (value, key) => this.$refs[key].$el || this.$refs[key])
      // order by them by vertical position
      const $orderedFields = sortBy($fields, field => {
        return field.offsetTop
      })
      // return the first result
      return $orderedFields[0]
    }
  }
}
