const consola = require('consola')
const logger = consola.withTag('axios')

export default function ({ $axios, redirect }) {
  $axios.onRequest(config => {
    logger.info('Request', config.method, config.url)
  })
  $axios.onResponse(response => {
    logger.info('Response', response.data)
  })

  $axios.onError(error => {
    logger.error(error)
    // @todo handle this nicely
    // const code = parseInt(error.response && error.response.status)
    // if (code === 400) {
    //   redirect('/400')
    // }
  })
}
