<template lang="pug">
v-btn(
  v-bind="{ ...$attrs, ...$props }"
  v-on="$listeners"
  :class="classes"
  :dark="isDarkThemeColour"
  :style="styles"
)
  .grey-200--text
    slot
</template>

<script>
import { Sizeable } from '@/mixins'

export default {
  mixins: [
    Sizeable
  ],
  inheritAttrs: false,
  props: {
    color: {
      type: String,
      default: 'primary-300',
    },
    /**
     * Will make the button take the full container width on screen sizes sm and xs.
     * The default behaviour is not to resize.
     */
    responsive: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    /**
     * Conditionally set the theme to dark if we're using our brand-pink or a dark brand colour
     * @returns {boolean}
     */
    isDarkThemeColour () {
      return this.dark || this.color === 'primary-500' || this.color === 'orange'
    },
    styles () {
      return {
        color: 'var(--v-' + this.color + '-800-base) !important',
        boxShadow: 'inset 0 1px 0 var(--v-' + this.color + '-300-base), 0 1px 2px hsla(0, 0%, 0%, 0.12),  0 1px 3px hsla(0, 0%, 0%, 0.24)'
      }
    },
    classes () {
      return {
        'h-button': true,
        'h-button--responsive': this.responsive,
        'h-button--x-small': this.xSmall,
        'h-button--small': this.small,
        ...this.sizeClasses
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$height-medium: 55px;
$height-small: 30px;
$height-x-small: 22px;

.v-btn.h-button {
  max-width: 100%;
  height: auto !important;
  min-width: initial !important;
  padding: 10px 21px !important;
  color: var(--v-primary-800-base) !important;
  letter-spacing: 1px;
  font-family: "Poppins", sans-serif;
  border-radius: 30px;

  &--x-large {
    padding: 12px 30px !important;
    @extend %paragraph-x-large;
  }

  &--large {
    padding: 12px 30px !important;
    @extend %paragraph-large;
  }

  &--default {
    @extend %paragraph-regular;
  }

  &--small {
    padding: 6px 12px !important;
    @extend %paragraph-x-small;
  }

  &--x-small {
    padding: 3px 7px !important;
    @extend %small;
  }

  // Responsive
  &--responsive {
    @include sm-and-down {
      display: flex;
      flex: 1;
      margin: 6px 0;
      width: 100%;
    }
  }
}
</style>
