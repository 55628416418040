<template lang="pug">
.h-footer.primary-100--text
  .primary-900.d-block.py-5
    h-container
      v-row
        v-col
          .mr-10
            nuxt-link(to="/").d-inline-flex.h-header__home-link.unstyled--link.mb-3
              .d-flex.align-center
                .heading-font-family.font-weight-bold.paragraph-large.grey-200--text unset
                img(:src="require('~icons/logo.svg')" height="10" width="10").d-inline-block.mt-1.ml-1
            .paragraph-small Unset is your #[span.yellow-200--text Laravel notification] testing & debugging inbox, for non-production environments. Ready for #[span.orange-200--text email], #[span.orange-200--text SMS] and #[span.orange-200--text webhook] notifications.
        v-col
          .orange-500--text.paragraph-small.font-weight-bold.uppercase Pages
          v-row
            v-col
              .mb-3
                nuxt-link(to="/pricing").grey-500--text Pricing
              .mb-3
                nuxt-link(to="/how-unset-works").grey-500--text How Unset works
            v-col
              .mb-3
                nuxt-link(to="/features").grey-500--text Features
              .mb-3
                nuxt-link(to="/contact").grey-500--text Contact
        v-col(v-if="$route.name !== 'index'")
          .d-flex
            v-avatar(size="30" color="primary").mr-2.mt-1
              v-img(:src="require('~images/harlan-wilton.jpg')")
            div.primary-700.primary-200--text.px-4.py-2.ml-2.rounded-xl.max-width-paragraph.home__bubble.box-shadow--small
              .paragraph-x-small.d-inline-block.mb-3 Hey, I'm Harlan #[span.emoji 👋]
              .paragraph-x-small.mb-2 I'm building Unset to help developers like you scale your Laravel notifications. Got 2 minutes free? I'd love to hear from you #[span.emoji 😊]
              a(href="mailto:harlan@unset.com?subject=Hey :)").font-weight-bold.paragraph-x-small.primary-200--text harlan@unset.com
    .text-center
      small.h-footer__copy.mt-2.d-block.grey--text.
        &copy; {{ currentYear }} Unset
</template>


<script>
export default {
  computed: {
    currentYear () {
      return (new Date()).getFullYear()
    }
  }
}
</script>


<style lang="scss" scoped>
.h-footer {
  border-top: 3px solid var(--v-orange-500-base) !important;
}
svg {
  height: 30px;
}
</style>
