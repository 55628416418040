import faker from 'faker/locale/en_AU'

export default () => {
  const firstName = faker.name.firstName()
  const lastName = faker.name.lastName()

  return {
    id: faker.random.uuid(),
    name: firstName + ' ' + lastName,
    firstName,
    lastName,
    phoneNumber: faker.phone.phoneNumber(),
    email: faker.internet.email(),
    avatar: faker.internet.avatar(),
  }
}
