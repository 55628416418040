<template lang="pug">
.h-loader(:class="classes")
  .text-center(v-if="value" :class="['my-' + verticalMargin]").fill-width
    v-progress-circular(
      indeterminate
      :color="color"
      :size="spinnerSize"
      :width="spinnerWidth"
      :class="{ 'mb-3': $scopedSlots.title }"
    )
    slot(name="title")
  template(v-else)
    slot
</template>
<script>
import { Sizeable } from '@/mixins'

/**
 * KLoader is a helper component for any UI state where we need to indicate a loading state following
 * by showing some content.
 *
 * See /demo/loaders for examples
 */
export default {
  mixins: [
    Sizeable
  ],
  props: {
    /**
     * Value of the loading state. True means that the component is loading something and we should
     * show the spinner. False will show the slot content.
     */
    value: {
      type: Boolean,
      required: false,
    },
    /**
     * The default spinner colour
     */
    color: {
      type: String,
      default: 'primary',
    }
  },
  data () {
    return {
      // Are we switching loading states currently (effects fade in)
      switchingState: false,
      // The timeout used to toggle the loading state changes (effects fade in)
      switchingStateTimeout: null,
    }
  },
  computed: {
    spinnerSize () {
      if (this.large) {
        return 98
      }
      if (this.small) {
        return 20
      }
      if (this.size) {
        return this.size
      }
      return 32
    },
    spinnerWidth () {
      if (this.large) {
        return 7
      }
      if (this.small) {
        return 2
      }
      if (this.width) {
        return this.width
      }
      return 4
    },
    verticalMargin () {
      if (this.large) {
        return 12
      }
      if (this.small) {
        return 2
      }
      return 5
    },
    classes () {
      return {
        'h-loader--loaded': !this.value,
        'h-loader--loading': this.value,
        'h-loader--switching-state': this.switchingState,
      }
    }
  },
  watch: {
    value () {
      // whenever value changes from the default state
      this.switchingState = true
      // we toggle the data here so it will toggle the loading state class
      // which handles the fade in effect for us
      clearTimeout(this.switchingStateTimeout)
      this.switchingStateTimeout = setTimeout(() => {
        this.switchingState = false
      }, 1000)
    }
  },
  destroyed () {
    clearTimeout(this.switchingStateTimeout)
  },
}
</script>

<style lang="scss" scoped>
.h-loader {
  &--switching-state {
    animation: fade-in .5s;
  }
}
</style>
