// pathify
import { make } from 'vuex-pathify'

// base state
const state = () => ({
  activeInbox: null,
  activeNotification: null,
  inboxes: [],
  notifications: [],
})

// getter overrides
const getters = {

}

// automatically generate mutations
const mutations = {
  // create SET_VALUE automatically
  ...make.mutations(state)
}

// manually-created actions
const actions = {
  ...make.actions(state),
}

// export store
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
