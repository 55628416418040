/**
 * ChangesScroll mixin handles page movement.
 */
export default {
  /**
   * On mount we scroll to any hashes that are set
   */
  mounted () {
    // if the user has a url hash then we try and scroll to that ref
    const hash = window.location.hash.replace('#', '')
    if (hash && hash.length > 0 && this.$refs[hash]) {
      setTimeout(() => {
        this.scrollToRef(hash, { offset: 50 })
      }, 1000)
    }
  },
  methods: {
    /**
     * @param duration default is 300, defines the amount of time the scroll should go for
     * Scrolls to the top of the page when called
     * @returns {void | Promise<void>}
     */
    scrollToTop (duration = 300) {
      this.$nextTick(() => {
        this.$vuetify.goTo(0, {
          duration: duration
        })
      })
    },
    /**
     * Scrolls to a given target with modifiers if passed
     * @param target the target component that the page should be scrolled to
     * @param offset is the verticle offset of the scroll
     * @param duration of the scroll
     * @returns {void | Promise<void> | *}
     */
    scrollToTarget (target, {
      offset = 0,
      duration = 300
    } = {}) {
      if (!target) {
        return
      }
      return this.$nextTick(() => {
        return this.$vuetify.goTo(target, {
          duration: duration,
          offset: offset
        })
      })
    },
    /**
     * Scroll to a specific ref using the ref name
     * @param ref
     * @param offset
     * @param duration
     */
    scrollToRef (ref, {
      offset = 0,
      duration = 300
    } = {}) {
      this.scrollToTarget(this.$refs[ref], {
        offset: offset,
        duration: duration
      })
    }
  }
}
