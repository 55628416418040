<template lang="pug">
v-container(v-bind="$attrs" :class="sizeClasses").h-container
  slot
</template>

<script>
import { Sizeable } from '@/mixins'

export default {
  mixins: [
    Sizeable
  ]
}
</script>

<style scoped lang="scss">
.h-container {

  &--semi-medium {
    width: 1078px;
  }

  &--small {
    padding: 15px 30px;
    @include sm-and-up {
      padding: 15px 60px;
      width: 650px;
    }
    @include md-and-up {
      padding: 20px 100px;
      width: 750px;
    }
    @include lg-and-up {
      padding: 30px 150px;
      width: 1266px;
    }
  }

  &--x-small {
    padding: 15px 30px;
    @include sm-and-up {
      padding: 15px 60px;
      width: 600px;
    }
    @include md-and-up {
      padding: 20px 100px;
      width: 750px;
    }
    @include lg-and-up {
      padding: 30px 150px;
      width: 900px;
    }
  }
}
</style>
