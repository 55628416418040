import Vue from 'vue'
import bugsnag from '@bugsnag/js'
import bugsnagVue from '@bugsnag/plugin-vue'
import { isEmpty } from 'lodash'
const consola = require('consola')
const logger = consola.withTag('hzw:bugsnag')

export default ({ env, $logger, app }, inject) => {
  if (isEmpty(env.BUGSNAG_KEY)) {
    inject('bugsnag', {
      notify: (e) => {
        logger.error('Bugsnag error thrown, captured locally because of missing Bugsnag API Key.', e)
        return e
      }
    })
    return
  }
  // setup bugsnag
  const bugsnagClient = bugsnag({
    apiKey: env.BUGSNAG_KEY,
    appVersion: env.VERSION,
    logger: $logger,
    releaseStage: process.env.NODE_ENV,
    notifyReleaseStages: [ 'production', 'uat' ]
  })
  bugsnagClient.use(bugsnagVue, Vue)
  inject('bugsnag', bugsnagClient)
}
