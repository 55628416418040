export default {
  props: {
    large: {
      type: Boolean,
      default: false
    },
    medium: {
      type: Boolean,
      default: false
    },
    semiMedium: {
      type: Boolean,
      default: false
    },
    size: {
      type: [ Number, String ],
      default: 0
    },
    small: {
      type: Boolean,
      default: false
    },
    xSmall: {
      type: Boolean,
      default: false
    },
    xLarge: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * Generate classes based on the size props + components tag.
     * @returns {{}}
     */
    sizeClasses () {
      const componentPrefix = this.$options._componentTag
      return {
        [componentPrefix + '--large']: this.large,
        [componentPrefix + '--medium']: this.medium,
        [componentPrefix + '--semi-medium']: this.semiMedium,
        [componentPrefix + '--small']: this.small,
        [componentPrefix + '--x-small']: this.xSmall,
        [componentPrefix + '--x-large']: this.xLarge,
        [componentPrefix + '--default']: !this.xLarge &&
        !this.xSmall &&
        !this.small &&
        !this.semiMedium &&
        !this.medium &&
        !this.large
      }
    }
  }
}
