import faker from 'faker/locale/en_AU'

const channel = '#' + faker.random.word()

export default (notifiable) => {
  return {
    type: 'webhook',
    vendor: 'slack',
    errors: 0,
    warnings: 0,
    from: {
      name: faker.name.findName(),
      number: faker.phone.phoneNumber()
    },
    title: faker.random.words(),
    channel,
    subTitle: channel,
    to: 'Channel: ' + channel
  }
}
