export default async ({ $auth }) => {
  if (!$auth.loggedIn) {
    return
  }

  // set global axios header so the api works
  $auth.ctx.app.$axios.setHeader('x-api-key', $auth.user['https://api.unset.emailunset_api_key'])

  // ge the users team if not set
  if (!$auth.user.default_team) {
    const profile = await $auth.ctx.app.$axios.$get('https://api.unset.email/me')
    $auth.setUser({
      ...$auth.user,
      ...profile,
    })
  }
}
