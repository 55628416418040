export default {
  // need to wrap the v-model attributes so that it works
  props: {
    errorMessages: {
      type: Array,
      default: () => []
    },
    displayErrorsOnBlur: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      tooltipActivator: '',
      displayErrors: true
    }
  },
  computed: {
    visibleInlineErrorMessages () {
      if (!this.displayErrors) {
        return []
      }
      return this.errorMessages
    },
    hasVisibleError () {
      if (!this.displayErrors) {
        return false
      }
      return this.errorMessages && this.errorMessages.length > 0
    }
  }
}
