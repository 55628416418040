// create wrapper function around window.dataLayer.push
// ideally it would inject global variables on each push
const defaults = {
  version: process.env.VERSION,
  api: process.env.API_URL,
  env: process.env.NODE_ENV
}

/**
 * Push a google tag manager data layer event.
 * @param event The name of the custom data layer event
 * @param data The data attached to the event
 */
const push = (event, data = {}) => {
  window.$consola.info('GTM Event', event, data)
  // only if the data layer object is available
  if (typeof window.dataLayer === 'undefined') {
    return
  }
  // get the default data variables
  // example userAuthenticated: true, userId: userModel.email, userType:  userModel.type
  window.dataLayer.push({ event: event, ...data, ...defaults })
}

// Triggers every time any page loads and it sends version number and api url
const INIT = 'hzw.init'
// Triggers everytime if user has authenticated it sends user id and usertype or else just default info
const PAGE_VIEW = 'hzw.page'
// Triggers when forms have a validation errors
const FORM_ERRORS = 'hzw.form.errors'


export {
  push,
  INIT,
  PAGE_VIEW,
  FORM_ERRORS
}

export default {
  push,
  INIT,
  PAGE_VIEW,
  FORM_ERRORS
}
