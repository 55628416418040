import faker from 'faker/locale/en_AU'

export default (notifiable) => {
  return {
    type: 'sms',
    vendor: 'twilio',
    errors: 0,
    warnings: 0,
    from: {
      name: faker.company.companyName(),
      number: faker.phone.phoneNumber()
    },
    to: notifiable.phoneNumber,
    subTitle: notifiable.firstName,
    title: faker.random.words(),
  }
}
