import HandlesErrors from './HandlesErrors'
import Sizeable from '../Sizeable'
import isString from 'lodash'

export default {
  mixins: [ HandlesErrors, Sizeable ],
  inheritAttrs: false,
  props: {
    value: {
      type: [ String, Number, Array, Object, Boolean ],
      required: false,
      default: ''
    },
    label: {
      type: [ String, Boolean ],
      default: ''
    },
    labelLarge: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    topLabel: {
      type: Boolean,
      default: true
    },
    i18nKey: {
      type: String,
      default: ''
    },
    i18nData: {
      type: Object,
      default: () => {}
    },
    sideHint: {
      type: Boolean,
      default: false
    },
    topHint: {
      type: Boolean,
      default: false
    },
    grey: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    maxlength: {
      type: [ Number, String ],
      default: null
    },
    counter: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * Whether or not the counter should appear below the input or inline.
     *
     * @todo This could be rewritten to take into account the text width and the width of the text input and smartly
     * switch to a bottom counter once it hits that point. For sake of simplicity and speed this is hardcoded to 20
     * for now.
     *
     * @returns {boolean}
     */
    displayCounterBottom () {
      if (isString(this.value) && this.value) {
        return this.value.length > 20
      }
      return false
    },
    labelI18n () {
      if (this.label) {
        return this.label
      }
      if (this.label === false) {
        return ''
      }
      const key = 'fields.' + this.i18nKey + '.label'
      if (this.$te(key)) {
        return this.$t(key, this.i18nData)
      }
      return ' '
    },
    placeholderI18n () {
      if (this.placeholder) {
        return this.placeholder
      }
      const key = 'fields.' + this.i18nKey + '.placeholder'
      if (this.$te(key)) {
        return this.$t(key, this.i18nData)
      }
      return ' '
    },
    labelClasses () {
      return {
        label: !this.labelLarge,
        'label--large': this.labelLarge
      }
    },
    hintI18n () {
      if (this.hint) {
        return this.hint
      }
      const key = 'fields.' + this.i18nKey + '.hint'
      if (this.$te(key)) {
        return this.$t(key, this.i18nData)
      }
      return ' '
    },
    fieldClasses () {
      const componentPrefix = this.$options._componentTag
      return {
        [componentPrefix]: true,
        [componentPrefix + '--grey']: this.grey,
        [componentPrefix + '--counter']: this.counter,
        ...this.sizeClasses
      }
    }
  },
  methods: {
    onFocus () {
      this.displayErrors = false
      this.$emit('focus')
    },
    onBlur () {
      if (this.displayErrorsOnBlur) {
        this.displayErrors = true
      }
      if (!this.$refs.field) {
        this.$emit('blur')
        return
      }
      if (this.$refs.field.internalValue) {
        let trimmed = this.$refs.field.internalValue.toString().trim()
        // turn multiple new lines into just 2
        trimmed = trimmed.replace(/\n\s*\n/g, '\n\n')
        // only if it's been updated !
        if (trimmed !== this.$refs.field.internalValue.toString()) {
          this.$emit('input', trimmed)
        }
      }
      this.$emit('blur')
    },
    handleInputClick () {
      this.displayErrors = false
      this.$refs.field.focus()
    }
  }
}
