const consola = require('consola')
const logger = consola.withTag('hzw:app')

export default (ctx, inject) => {
  logger.info('Ready!')
  if (typeof window !== 'undefined') {
    window.$consola = logger
  }
  // Inject it to nuxt context as $auth
  inject('logger', logger)
}
