import { startsWith } from 'lodash'

export default ({ app, store }) => {
  app.router.afterEach((to, from) => {
    // only if we're navigation to and from a page
    if (!from.matched[0] || !to.matched[0]) {
      return
    }
    // routes
    const fromName = from.matched[0].name
    const toName = to.matched[0].name
    console.log(fromName, toName)
    const inboxUrl = 'team-inboxes-inbox'
    if (startsWith(fromName, inboxUrl) && !startsWith(toName, inboxUrl)) {
      store.commit('authenticated/SET_ACTIVE_INBOX', null)
    }
  })
}
