import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3cec6adc = () => interopDefault(import('../app/pages/about.vue' /* webpackChunkName: "pages/about" */))
const _ae837b36 = () => interopDefault(import('../app/pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _6e9d2a48 = () => interopDefault(import('../app/pages/features.vue' /* webpackChunkName: "pages/features" */))
const _a5c7e990 = () => interopDefault(import('../app/pages/home2.vue' /* webpackChunkName: "pages/home2" */))
const _5fde22b4 = () => interopDefault(import('../app/pages/how-unset-works.vue' /* webpackChunkName: "pages/how-unset-works" */))
const _656d51a8 = () => interopDefault(import('../app/pages/onboard.vue' /* webpackChunkName: "pages/onboard" */))
const _032d7b0b = () => interopDefault(import('../app/pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _1e1f79d9 = () => interopDefault(import('../app/pages/auth/sign-in.vue' /* webpackChunkName: "pages/auth/sign-in" */))
const _7162fc9a = () => interopDefault(import('../app/pages/auth/signed-in.vue' /* webpackChunkName: "pages/auth/signed-in" */))
const _32d55ed4 = () => interopDefault(import('../app/pages/profile/settings.vue' /* webpackChunkName: "pages/profile/settings" */))
const _114d3fd1 = () => interopDefault(import('../app/pages/ui/buttons.vue' /* webpackChunkName: "pages/ui/buttons" */))
const _f1ae8d12 = () => interopDefault(import('../app/pages/ui/colours.vue' /* webpackChunkName: "pages/ui/colours" */))
const _595832ad = () => interopDefault(import('../app/pages/ui/elevation.vue' /* webpackChunkName: "pages/ui/elevation" */))
const _5fcc1ac9 = () => interopDefault(import('../app/pages/ui/inputs.vue' /* webpackChunkName: "pages/ui/inputs" */))
const _1597eccc = () => interopDefault(import('../app/pages/ui/type.vue' /* webpackChunkName: "pages/ui/type" */))
const _0141a557 = () => interopDefault(import('../app/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1d7436f8 = () => interopDefault(import('../app/pages/_team/index.vue' /* webpackChunkName: "pages/_team/index" */))
const _62d9f82e = () => interopDefault(import('../app/pages/_team/inboxes/index.vue' /* webpackChunkName: "pages/_team/inboxes/index" */))
const _20f727f8 = () => interopDefault(import('../app/pages/_team/inboxes/add.vue' /* webpackChunkName: "pages/_team/inboxes/add" */))
const _1e4e9cfa = () => interopDefault(import('../app/pages/_team/inboxes/_inbox/index.vue' /* webpackChunkName: "pages/_team/inboxes/_inbox/index" */))
const _cfc69d92 = () => interopDefault(import('../app/pages/_team/inboxes/_inbox/inbox.vue' /* webpackChunkName: "pages/_team/inboxes/_inbox/inbox" */))
const _1dcc36c5 = () => interopDefault(import('../app/pages/_team/inboxes/_inbox/integration.vue' /* webpackChunkName: "pages/_team/inboxes/_inbox/integration" */))
const _c02bd8fc = () => interopDefault(import('../app/pages/_team/inboxes/_inbox/settings.vue' /* webpackChunkName: "pages/_team/inboxes/_inbox/settings" */))
const _67207e40 = () => interopDefault(import('../app/pages/_team/inboxes/_inbox/snapshots.vue' /* webpackChunkName: "pages/_team/inboxes/_inbox/snapshots" */))
const _2c7cd30a = () => interopDefault(import('../app/pages/_team/inboxes/_inbox/_notification.vue' /* webpackChunkName: "pages/_team/inboxes/_inbox/_notification" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _3cec6adc,
    name: "about"
  }, {
    path: "/contact",
    component: _ae837b36,
    name: "contact"
  }, {
    path: "/features",
    component: _6e9d2a48,
    name: "features"
  }, {
    path: "/home2",
    component: _a5c7e990,
    name: "home2"
  }, {
    path: "/how-unset-works",
    component: _5fde22b4,
    name: "how-unset-works"
  }, {
    path: "/onboard",
    component: _656d51a8,
    name: "onboard"
  }, {
    path: "/pricing",
    component: _032d7b0b,
    name: "pricing"
  }, {
    path: "/auth/sign-in",
    component: _1e1f79d9,
    name: "auth-sign-in"
  }, {
    path: "/auth/signed-in",
    component: _7162fc9a,
    name: "auth-signed-in"
  }, {
    path: "/profile/settings",
    component: _32d55ed4,
    name: "profile-settings"
  }, {
    path: "/ui/buttons",
    component: _114d3fd1,
    name: "ui-buttons"
  }, {
    path: "/ui/colours",
    component: _f1ae8d12,
    name: "ui-colours"
  }, {
    path: "/ui/elevation",
    component: _595832ad,
    name: "ui-elevation"
  }, {
    path: "/ui/inputs",
    component: _5fcc1ac9,
    name: "ui-inputs"
  }, {
    path: "/ui/type",
    component: _1597eccc,
    name: "ui-type"
  }, {
    path: "/",
    component: _0141a557,
    name: "index"
  }, {
    path: "/:team",
    component: _1d7436f8,
    name: "team"
  }, {
    path: "/:team/inboxes",
    component: _62d9f82e,
    name: "team-inboxes"
  }, {
    path: "/:team/inboxes/add",
    component: _20f727f8,
    name: "team-inboxes-add"
  }, {
    path: "/:team/inboxes/:inbox",
    component: _1e4e9cfa,
    name: "team-inboxes-inbox"
  }, {
    path: "/:team/inboxes/:inbox/inbox",
    component: _cfc69d92,
    name: "team-inboxes-inbox-inbox"
  }, {
    path: "/:team/inboxes/:inbox/integration",
    component: _1dcc36c5,
    name: "team-inboxes-inbox-integration"
  }, {
    path: "/:team/inboxes/:inbox/settings",
    component: _c02bd8fc,
    name: "team-inboxes-inbox-settings"
  }, {
    path: "/:team/inboxes/:inbox/snapshots",
    component: _67207e40,
    name: "team-inboxes-inbox-snapshots"
  }, {
    path: "/:team/inboxes/:inbox/:notification",
    component: _2c7cd30a,
    name: "team-inboxes-inbox-notification"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
