import Vuex from 'vuex'
import pathify from 'vuex-pathify'
import createPersistedState from 'vuex-persistedstate'
import authenticated from './modules/authenticated'

const plugins = [
  pathify.plugin,
  createPersistedState({
    key: 'app.v' + process.env.VERSION,
    paths: [
      '',
    ],
    arrayMerger (store, saved) {
      if (saved.length > 0) {
        return saved
      }
      return store
    }
  })
]

export default () => {
  return new Vuex.Store({
    plugins,
    modules: {
      authenticated
    }
  })
}
