import Vuetify from 'vuetify/lib'
import Vue from 'vue'

import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { faSlack } from '@fortawesome/free-brands-svg-icons'

config.autoAddCss = false
library.add(
  fas,
  far,
  faSlack
) // Include needed icons

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

const ICONS_FONTAWESOME = {
  settings: 'fas fa-cogs',
  inbox: 'fas fa-inbox',
  terminal: 'fas fa-terminal',
  bug: 'fas fa-bug',
  users: 'fas fa-users',
  logout: 'fas fa-sign-out-alt',
  times: 'fas fa-times-circle',
  mail: 'fas fa-envelope',
  search: 'fas fa-search',
  back: 'fas fa-arrow-left',
  right: 'fas fa-arrow-right',
  check: 'fas fa-check-circle',
  slack: 'fab fa-slack',
  down: 'fas fa-chevron-down',
  folder: 'fas fa-folder',
}

const convertToComponentDeclarations = (component, iconSet) => {
  const result = {}

  for (const key in iconSet) {
    result[key] = {
      component,
      props: {
        icon: (iconSet[key]).split(' fa-')
      }
    }
  }

  return result
}


// disabling warning for the svg native
Vue.config.warnHandler = (message, vm, trace) => {
  if (message === 'The .native modifier for v-on is only valid on components but it was used on <svg>.') {
    message = null
    vm = null
    trace = null
  }
}

function hsl (h, s, l) {
  h /= 360
  s /= 100
  l /= 100
  let r, g, b
  if (s === 0) {
    r = g = b = l // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1
      if (t > 1) t -= 1
      if (t < 1 / 6) return p + (q - p) * 6 * t
      if (t < 1 / 2) return q
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
      return p
    }
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s
    const p = 2 * l - q
    r = hue2rgb(p, q, h + 1 / 3)
    g = hue2rgb(p, q, h)
    b = hue2rgb(p, q, h - 1 / 3)
  }
  const toHex = x => {
    const hex = Math.round(x * 255).toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`
}

export default ({ app }) => {
  Vue.use(Vuetify)

  app.vuetify = new Vuetify({
    theme: {
      dark: false,
      options: {
        customProperties: true,
        cspNonce: 'dQw4w9WgXcQ',
        variations: false
      },
      themes: {
        light: {

          // greys :)
          'grey-100': '#FFFFFF',
          'grey-200': '#F8FAFC',
          'grey-300': '#F1F5F8',
          'grey-400': '#DAE1E7',
          'grey-500': '#B8C2CC',
          grey: '#B8C2CC',
          'grey-600': '#8795A1',
          'grey-700': '#606F7B',
          'grey-800': '#3D4852',
          'grey-900': '#22292F',

          // primary :)
          'primary-100': '#c1daf1',
          'primary-200': '#97c1e7',
          'primary-300': '#6ea8de',
          'primary-400': '#4490d4',
          'primary-500': '#2b76bb',
          primary: '#2b76bb',
          'primary-600': '#215c91',
          'primary-700': '#184268',
          'primary-800': '#0e273e',
          'primary-900': '#050d15',

          // orange :)
          'orange-100': '#f9c4b9',
          'orange-200': '#f49d8a',
          'orange-300': '#f0755b',
          'orange-400': '#ec4e2d',
          'orange-500': '#ff522d',
          orange: '#ff522d',
          'orange-600': '#a4290f',
          'orange-700': '#751d0b',
          'orange-800': '#461206',
          'orange-900': '#170602',

          // yellow :)
          'yellow-100': hsl(43, 90, 90),
          'yellow-200': hsl(42, 87, 85),
          'yellow-300': hsl(41, 84, 75),
          'yellow-400': hsl(40, 83, 65),
          // mid
          'yellow-500': hsl(38, 80, 55),
          yellow: hsl(38, 80, 55),
          // To make a color lighter, rotate the hue towards the nearest bright hue — 60°,
          // 180°, or 300°.
          // To make a color darker, rotate the hue towards the nearest dark hue — 0°,
          // 120°, or 240°.
          'yellow-600': hsl(36, 83, 35),
          'yellow-700': hsl(34, 84, 25),
          'yellow-800': hsl(32, 87, 15),
          'yellow-900': hsl(30, 90, 5),


          // vuetify pallet - Avoid using
          // alert: '#DA1414',
          // error: '#DA1414',

          // accent: '#f0c376',
          // secondary: '#ee6143',
          // tertiary: '#2a84a7',

          // // greys
          // lightGrey: '#f9f9f9',
          // 'brand-warm-grey': '#9F9FB3',
          // 'brand-grey-90': '#5b5b63',
          // 'brand-grey-40': '#DCDCE3',
          // 'brand-grey-20': '#F1F1F3',
          // 'brand-grey-10': '#F2F2F2',

          // 'brand-dark-blue': '#0d2439',
          // 'brand-light-blue': '#394382',

          // anchor: '#ff522d',

          // 'brand-orange': '#ff522d',
          // 'brand-blue': '#2a84a7',
        }
      }
    },
    icons: {
      iconfont: 'faSvg',
      values: {
        // font awesome icons
        ...convertToComponentDeclarations('font-awesome-icon', ICONS_FONTAWESOME),
      }
    },
  })
}
