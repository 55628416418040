import { render, staticRenderFns } from "./inbox.vue?vue&type=template&id=2ba6dffe&scoped=true&lang=pug&"
import script from "./inbox.vue?vue&type=script&lang=js&"
export * from "./inbox.vue?vue&type=script&lang=js&"
import style0 from "./inbox.vue?vue&type=style&index=0&id=2ba6dffe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ba6dffe",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import HButton from '@/components/Button/HButton.vue'
import HHeader from '@/components/HHeader.vue'
import HLoader from '@/components/Loader/HLoader.vue'
import HNotificationList from '@/components/Notification/HNotificationList.vue'
import { VApp } from 'vuetify/lib/components/VApp';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {HButton,HHeader,HLoader,HNotificationList,VApp,VIcon,VMain})
